import React from "react"
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Logo_AVosID from './img/Logo_AVosID.png';
import Logo_CoopOasis from './img/Logo_CoopOasis.png';
import LazyLoad from 'react-lazyload'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import CardPost from '../components/CardPost'
import CardOasis from '../components/CardOasis'

class HomeIndex extends React.Component {
  render() {
    const site = get(this, 'props.data.site.siteMetadata')
    const posts = get(this, 'props.data.remark.posts')
    const oasis = get(this, 'props.data.oasis.posts')

    // on recupere tous les oasis libres
    const tabCardOasis = []
    oasis.forEach((data, i) => {
      tabCardOasis.push(
          <LazyLoad height={1} offset={500} once={true} key={i}>
            <CardOasis data={data.post} site={site} isIndex={true} key={i} />
          </LazyLoad>
        )
    })

    // on recupere les derniers articles pour mettre en une
    const cardPosts = []
    posts.forEach((data, i) => {
      const layout = get(data, 'post.frontmatter.layout')
      const path = get(data, 'post.path')
      if (layout === 'post' && path !== '/404/') {
        cardPosts.push(
          <LazyLoad height={1} offset={500} once={true} key={i}>
            <CardPost data={data.post} site={site} isIndex={true} key={i} />
          </LazyLoad>
        )
      }
    })

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={get(site, 'title')}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: `@${get(site, 'twitter')}` },
            { property: 'og:title', content: get(site, 'title') },
            { property: 'og:type', content: 'website' },
            { property: 'og:description', content: get(site, 'description') },
            { property: 'og:url', content: get(site, 'url') },
            {
              property: 'og:image',
              content: `${get(site, 'url')}/img/logo.png`,
            },
          ]}
        />
        <div className="container">
          <div className="row">
            <div className="col-md-5 text-right">
              <h1 className="underlined">Bienvenue sur le site de <br />l'écohameau <strong>du Plessis</strong></h1><br />
              Situé à Pontgouin en Eure-et-Loir, l'écohameau du Plessis est un habitat participatif écologique qui rassemble 28 foyers.<br />
              Il comprend 4 hectares de jardins collectifs en permaculture, une grande maison commune, plusieurs autres bâtiments communs et des maisons écologiques individuelles.<br />
              Initié depuis 2013, l'écohameau se construit depuis 2019. Mi 2023, 12 maisons sont déjà habitées. Il permet aux habitants de mettre en oeuvre un mode de vie écologique et solidaire.<br />
              <Link to="/le-projet">Découvrez en détails ce projet innovant !</Link>
            </div>
            <div className="col-md-7">
              <img className="img-responsive" src={'/img/plan-plessis.jpg'} alt="photo" />
            </div>
          </div>
        </div>
        <div className="articles">
          <div className="container">
            <div className="row">
              <div className="col-md-3 text-right"><h1 className="underlined">Dernières actus</h1></div>
              <div className="col-md-9"></div>
            </div>
            {cardPosts}
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Link to="/actus/" className="btn btn-block btn-primary px-5">
                  <i className="fa fa-plus"></i> Voir toutes les actualités
                </Link>
              </div>
              <div className="col-md-3"></div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-3">
              <Link to="https://www.centre-valdeloire.fr/agir/valoriser-le-territoire/contrats-regionaux-de-solidarite-territoriale/vos-id">
                <img className="AVosID" src={Logo_AVosID} alt="Logo Région Centre" />
              </Link>
              </div>
              <div className="col-md-3">
                L'écohameau du Plessis a reçu 2 soutiens financiers
                de la Région Centre Val de Loire :
                le premier entre 2019 et 2021 et le deuxième en 2023.
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-3">
              <Link to="https://cooperative-oasis.org/">
                <img className="CoopOasis" src={Logo_CoopOasis} alt="Logo Coopérative Oasis" />
              </Link>
              </div>
              <div className="col-md-3">
              <br />
                L'écohameau du Plessis a signé
                une convention de partenariat de 10 ans
                avec la Coopérative Oasis,
                incluant un prêt de 190 000 euros et
                un accompagnement chaque année.
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
      }
    }
    remark: allMarkdownRemark(
      limit:4,
      filter: {
        frontmatter:{
          type:{eq : "blog"}
        }
      },
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      posts: edges {
        post: node {
          fileAbsolutePath
          html
          excerpt(pruneLength: 300)
          frontmatter {
            layout
            title
            path
            categories
            date(formatString: "DD.MM.YYYY")
            type
            image {
              childImageSharp {
                resize(width: 600) {
                  src
                }
              }
            }
            description
          }
        }
      }
    }
    oasis: allMarkdownRemark(
      limit:3,
      filter: {
        frontmatter:{
          type:{eq : "oasis"}
        }
      },
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      posts: edges {
        post: node {
          fileAbsolutePath
          html
          excerpt(pruneLength: 300)
          frontmatter {
            layout
            title
            path
            avancement
            montant
            departement
            image {
              childImageSharp {
                resize(width: 600) {
                  src
                }
              }
            }
            description
          }
        }
      }
    }
  }
`
